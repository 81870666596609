import React from 'react'

const rootElement = ({ children }) => {
  return (
    <>
      <>{children}</>
    </>
  )
}

export default rootElement
